import { createSelector } from "@reduxjs/toolkit";
import {
  getDateTimeWithFormat,
  getTotalPriceText,
  twoDecimalFormatter,
  getRewardsString,
  emailRegex,
  phoneRegex,
  roundToTwoDecimals,
  getAgentErrorSubtitle,
  getAgentErrorTitle,
  CurrencyFormatters,
  IconName,
  truncateToTwoDecimals,
  getCheckoutCreditBreakdown,
  TEST_CARD_LAST_FOURS,
} from "halifax";
import {
  ErrorTitles,
  FiatPrice,
  CallState,
  PaymentAmountEnum,
  PaymentSplitRequest,
  PaymentSplitRequestEnum,
  CarPriceQuoteRequest,
  CarPriceQuoteScheduleRequest,
  PaymentPriceQuoteRequestEnum,
  ErrorCode,
  PaymentErrorEnum,
  PaymentError,
  RewardsPrice,
  PaymentVerifyResultEnum,
  ReviewedCarDetailsCheckoutProperties,
  CompleteBuyCarProperties,
  RewardsAccountMinimumRequirementState,
  getRewardsAccountMinimumRequirementState,
  TypeOfPaymentEnum,
  UserCardPaymentType,
  SplitPaymentType,
  RewardsPaymentType,
  PaymentType,
  PriceQuoteCarProperties,
  PaymentSplitRequestV2,
  PaymentV2,
  TravelWalletOfferPayment,
  TravelWalletCreditPayment,
  PaymentV2Enum,
  Rewards,
  UserCard,
  Prices,
  AvailableProductEnum,
  ITrackingProperties,
  OffersForProductsRequest,
  LocationKindEnum,
  BagSize,
  LocationCode,
  CreditCard,
  CustomerAccountRole,
  RewardsAccount,
  ProductClassEnum,
} from "redmond";
import dayjs from "dayjs";
import { IStoreState } from "../../../../reducers/types";
import {
  getCarAvailabilityEntryPoint,
  getDropOffDateSearched,
  getPickUpDateSearched,
  getPickUpLocationSearched,
} from "../../../availability/reducer";
import {
  getCarShopSelectedVehicle,
  getExtraInfo,
  getSelectedVehiclePolicyCompliance,
  getViewedCarDetailsProperties,
} from "../../../shop/reducer/selectors";
import {
  getAgentEmail,
  getAllowRewardsWithPolicy,
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../../../rewards/reducer";
import * as textConstants from "./textConstants";
import { DEFAULT_DRIVER_AGE, getDriverAge } from "../../../search/reducer";
import { isEqual } from "lodash-es";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../../api/config";
import {
  AVAILABLE,
  CARS_V1_PURCHASE_FLOW,
} from "../../../../context/experiments";
import {
  PaymentOpaqueValue,
  Payment,
  PurchaseError,
  PurchaseErrorEnum,
  PaymentError as NewPaymentError,
  ProductError,
  ErrorCode as NewErrorCode,
} from "@b2bportal/purchase-api";
import { GroundError, GroundErrorEnum } from "@b2bportal/ground-api";

export const getUserPassengers = (state: IStoreState) =>
  state.carBook.userPassengers;

export const getUserSelectedTravelerId = (state: IStoreState) =>
  state.carBook.userSelectedTravelerId;

export const getUserPassengerCallState = (state: IStoreState) =>
  state.carBook.userPassengerCallState;

export const getConfirmationEmail = (state: IStoreState) =>
  state.carBook.confirmationEmailAddress;

export const getConfirmationPhoneNumber = (state: IStoreState) =>
  state.carBook.confirmationPhoneNumber;

export const getPaymentMethods = (state: IStoreState) =>
  state.carBook.paymentMethods;

export const getPaymentMethod = (state: IStoreState) =>
  state.carBook.paymentMethod;

export const getSelectedPaymentMethodId = (state: IStoreState) =>
  state.carBook.selectedPaymentMethodId;

export const getListPaymentMethodsCallState = (state: IStoreState) =>
  state.carBook.listPaymentMethodCallState;

export const getDeletePaymentMethodCallState = (state: IStoreState) =>
  state.carBook.deletePaymentMethodCallState;

export const getFetchPaymentMethodCallState = (state: IStoreState) =>
  state.carBook.fetchPaymentMethodCallState;

export const getVerifyPaymentMethodCallState = (state: IStoreState) =>
  state.carBook.verifyPaymentMethodCallState;

export const getPriceDifferenceAcknowledged = (state: IStoreState) =>
  state.carBook.priceDifferenceAcknowledged;

export const getSession = (state: IStoreState) => state.carBook.session;

export const getPriceQuote = (state: IStoreState) => state.carBook.priceQuote;

export const getOffers = (state: IStoreState) => state.carBook.offers;

export const getFetchApplicableTravelWalletItemsCallState = (
  state: IStoreState
) => state.carBook.fetchApplicableTravelWalletItemsCallState;

export const getBestOfferOverall = (state: IStoreState) =>
  state.carBook.bestOfferOverall;

export const getPriceQuoteTotal = createSelector(
  getPriceQuote,
  (priceQuote) => {
    if (!priceQuote) return null;

    return priceQuote.vehAvail.rentalRate.totalCharge;
  }
);

export const getPriceQuoteErrors = (state: IStoreState) =>
  state.carBook.priceQuoteErrors;

export const getSchedulePriceQuoteError = (state: IStoreState) =>
  state.carBook.schedulePriceQuoteError;

export const getScheduleBookError = (state: IStoreState) =>
  state.carBook.scheduleBookError;

export const getPollPriceQuoteCallState = (state: IStoreState) =>
  state.carBook.pollPriceQuoteCallState;

export const getSchedulePriceQuoteCallState = (state: IStoreState) =>
  state.carBook.schedulePriceQuoteCallState;

export const getConfirmationDetails = (state: IStoreState) =>
  state.carBook.confirmationDetails;

export const getConfirmationDetailsError = (state: IStoreState) =>
  state.carBook.confirmationDetailsErrors;

export const getConfirmationDetailsCallState = (state: IStoreState) =>
  state.carBook.confirmationDetailsCallState;

export const getOfferToApply = (state: IStoreState) =>
  state.carBook.offerToApply;

export const getTripPurpose = (state: IStoreState) => state.carBook.tripPurpose;

export const getCredit = (state: IStoreState) => {
  const credit = state.carBook.credit;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getCreditToApply = (state: IStoreState) => {
  const credit = state.carBook.creditToApply;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getTravelWalletItemsToApply = createSelector(
  getOfferToApply,
  getCreditToApply,
  (offerToApply, creditToApply) => ({
    offerToApply,
    creditToApply,
  })
);

export const getCarType = createSelector(
  getCarShopSelectedVehicle,
  (vehicle) => vehicle?.carModelDetails?.carSize
);
export const getPriceDifference = createSelector(
  getPriceQuote,
  getCarShopSelectedVehicle,
  getPriceDifferenceAcknowledged,
  (
    priceQuote,
    vehicle,
    acknowledged
  ): {
    hasDifference: boolean;
    isIncrease: boolean;
    predictedTotal: number;
    priceQuoteTotal: number;
    amount?: string;
  } => {
    if (!priceQuote || !vehicle || acknowledged) {
      return {
        hasDifference: false,
        isIncrease: false,
        predictedTotal: 0,
        priceQuoteTotal: 0,
      };
    }
    const predictedTotal = vehicle.payNow.fiat.value;
    const priceQuoteTotal = priceQuote.vehAvail.rentalRate.payNow.fiat.value;
    return {
      // If the difference is within a dollar we don't show the error modal
      hasDifference: Math.abs(priceQuoteTotal - predictedTotal) >= 1,
      isIncrease: priceQuoteTotal > predictedTotal,
      amount: Math.abs(priceQuoteTotal - predictedTotal)
        .toFixed(0)
        .toString(),
      predictedTotal,
      priceQuoteTotal,
    };
  }
);

export const getIsMissingLicense = createSelector(
  getUserSelectedTravelerId,
  getUserPassengers,
  (driverId, drivers) => {
    const driver = drivers.find((d) => d.id === driverId);
    return driverId && !driver?.driverLicense;
  }
);

export const getRewardsConversionFailed = (state: IStoreState) =>
  state.carBook.rewardsConversionFailed;

export const getHasError = createSelector(
  getUserPassengerCallState,
  getPollPriceQuoteCallState,
  getScheduleBookError,
  getSchedulePriceQuoteError,
  getConfirmationDetailsCallState,
  getPriceDifference,
  getPriceDifferenceAcknowledged,
  getRewardsConversionFailed,
  getVerifyPaymentMethodCallState,
  (
    passengerCallState,
    priceQuoteCallState,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsCallState,
    priceDifference,
    priceDifferenceAcknowledged,
    rewardsConversionFailed,
    verifyPaymentMethodCallState
  ) =>
    passengerCallState === CallState.Failed ||
    (!priceDifferenceAcknowledged && priceDifference.hasDifference) ||
    rewardsConversionFailed ||
    !!scheduleBookError ||
    !!schedulePriceQuoteError ||
    verifyPaymentMethodCallState === CallState.Failed ||
    confirmationDetailsCallState === CallState.Failed ||
    priceQuoteCallState === CallState.Failed
);

const getErrorMessage = (error: PaymentError, supplierRef?: string) => {
  const { PaymentError, code, msg = "" } = error as ErrorCode;
  const agentSubtitle = getAgentErrorSubtitle(PaymentError);
  const agentTitle = getAgentErrorTitle(PaymentError);
  let titles = textConstants.GENERIC_ERROR_TITLES;

  switch (PaymentError) {
    case PaymentErrorEnum.UserCardNotFound:
      titles = textConstants.USER_CARD_ERROR_TITLES;
      break;
    case PaymentErrorEnum.PriceUnavailableUnder25:
      titles = textConstants.UNDER_AGE_RATE_TITLES();
      break;
    case PaymentErrorEnum.QuoteError:
    case PaymentErrorEnum.BookError:
      titles = textConstants.QUOTE_ERROR_TITLES(msg, supplierRef);
      break;
    case PaymentErrorEnum.RedemptionFailure:
      titles = textConstants.REDEMPTION_FAILED_TITLES;
      break;
    case PaymentErrorEnum.FraudAutoReject:
    case PaymentErrorEnum.LikelyFraud:
      titles = textConstants.FRAUD_TITLES;
      break;
    case PaymentErrorEnum.ErrorCode:
      switch (code) {
        case PaymentErrorEnum.RedemptionFailure:
          titles = textConstants.REDEMPTION_FAILED_TITLES;
          break;
        default:
          titles = textConstants.GENERIC_ERROR_TITLES;
          break;
      }
      break;
    default:
  }

  return { ...titles, agentSubtitle, agentTitle };
};
export const getV1ErrorMessage = (
  error: PurchaseError,
  supplierRef?: string
) => {
  const { code = "" } = error as NewErrorCode;
  let agentSubtitle = getAgentErrorSubtitle(error.Error);
  let agentTitle = getAgentErrorTitle(error.Error);
  let titles = textConstants.GENERIC_ERROR_TITLES;

  switch (error.Error) {
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as NewPaymentError;
      if (paymentError.value.type === Payment.Rewards)
        titles = textConstants.REDEMPTION_FAILED_TITLES;
      break;
    case PurchaseErrorEnum.ErrorCode:
      agentSubtitle = getAgentErrorSubtitle(code);
      agentTitle = getAgentErrorTitle(code);
      switch (code) {
        case PaymentErrorEnum.UserCardNotFound:
          titles = textConstants.USER_CARD_ERROR_TITLES;
          break;
        case PaymentErrorEnum.PriceUnavailableUnder25:
          titles = textConstants.UNDER_AGE_RATE_TITLES();
          break;
        case PaymentErrorEnum.RedemptionFailure:
          titles = textConstants.REDEMPTION_FAILED_TITLES;
          break;
        case PaymentErrorEnum.FraudAutoReject:
        case PaymentErrorEnum.LikelyFraud:
          titles = textConstants.FRAUD_TITLES;
          break;
        default:
      }
      break;
    case PurchaseErrorEnum.ProductError:
      const errorEnum =
        "GroundError" in (error as ProductError).value.value
          ? ((error as ProductError).value.value as GroundError).GroundError
          : ((error as ProductError).value.value.PaymentError as string);
      agentSubtitle = getAgentErrorSubtitle(errorEnum);
      agentTitle = getAgentErrorTitle(errorEnum);
      switch (errorEnum) {
        case PaymentErrorEnum.UserCardNotFound:
          titles = textConstants.USER_CARD_ERROR_TITLES;
          break;
        case PaymentErrorEnum.PriceUnavailableUnder25:
          titles = textConstants.UNDER_AGE_RATE_TITLES();

          break;

        case GroundErrorEnum.InvalidLoyaltyNumber:
        case GroundErrorEnum.RewardsNotFound:
          titles = textConstants.QUOTE_ERROR_TITLES(
            GroundErrorEnum.InvalidLoyaltyNumber,
            supplierRef
          );
          break;
        case PaymentErrorEnum.QuoteError:
        case PaymentErrorEnum.BookError:
          titles = textConstants.QUOTE_ERROR_TITLES(
            (error as ProductError).value.value.msg,
            supplierRef
          );
          break;
        case PaymentErrorEnum.RedemptionFailure:
          titles = textConstants.REDEMPTION_FAILED_TITLES;
          break;
        case PaymentErrorEnum.FraudAutoReject:
        case PaymentErrorEnum.LikelyFraud:
          titles = textConstants.FRAUD_TITLES;
          break;
        case GroundErrorEnum.AdvanceLeadTime:
          titles = textConstants.ADVANCE_LEAD_TIME_TITLE;
          break;
        case GroundErrorEnum.AgeRequirement:
          titles = textConstants.AGE_REQUIREMENT_TITILE;
          break;
        case GroundErrorEnum.RateUnavailable:
          titles = textConstants.RATE_UNAVAILABLE_TITLES;
          break;
        case GroundErrorEnum.DuplicateBooking:
          titles = textConstants.DUPLICATE_BOOKING_TITLES;
          break;
        case GroundErrorEnum.OverlappingReservation:
          titles = textConstants.OVERLAPPING_RESERVATION_TITLES;
          break;
        case GroundErrorEnum.PriceChange:
          titles = textConstants.PRICE_CHANGE_TITLES;
          break;
        default:
      }
  }

  return { ...titles, agentSubtitle, agentTitle };
};

export const getContactEmail = (state: IStoreState) =>
  state.carBook.confirmationEmailAddress;

export const getContactPhone = (state: IStoreState) =>
  state.carBook.confirmationPhoneNumber;

export const getScheduleBookCallState = (state: IStoreState) =>
  state.carBook.scheduleBookCallState;

export const getSubmitForApprovalCallState = (state: IStoreState) =>
  state.carBook.submitForApprovalCallState;

export const getSubmitForApprovalError = (state: IStoreState) =>
  state.carBook.submitForApprovalError;

export const getHertzLoyaltyRewardsNumber = (state: IStoreState) =>
  state.carBook.hertzLoyaltyRewardsNumber;

export const getUseV1PurchaseFlow = (state: IStoreState) =>
  state.carBook.experiments?.[CARS_V1_PURCHASE_FLOW] === AVAILABLE;

export const priceQuoteParamsSelector = createSelector(
  getExtraInfo,
  getUserSelectedTravelerId,
  getContactEmail,
  getContactPhone,
  getAgentEmail,
  getHertzLoyaltyRewardsNumber,
  (
    extraInfo,
    personId,
    emailAddress,
    phoneNumber,
    agentEmail,
    hertzLoyaltyRewardsNumber
  ): CarPriceQuoteRequest | null => {
    if (!personId || !extraInfo) {
      return null;
    }
    let groundQuoteRequest: CarPriceQuoteScheduleRequest;
    if (hertzLoyaltyRewardsNumber) {
      const decodedOpaqueResponse = JSON.parse(
        atob(extraInfo?.opaqueBookRequest)
      );
      const encodedOpaqueResponse = btoa(
        JSON.stringify({
          ...decodedOpaqueResponse,
          vehicle: {
            ...decodedOpaqueResponse.vehicle,
            remarks: {
              ...decodedOpaqueResponse.vehicle?.remarks,
              CustLoyalty: hertzLoyaltyRewardsNumber,
            },
          },
        })
      );

      groundQuoteRequest = {
        opaquePriceRequest: encodedOpaqueResponse,
        driverId: personId,
        emailAddress: emailAddress || "",
        phoneNumber: phoneNumber || "",

        loyaltyRewardsNumbers: { hertz: hertzLoyaltyRewardsNumber },
      };
    } else {
      groundQuoteRequest = {
        opaquePriceRequest: extraInfo?.opaqueBookRequest,
        driverId: personId,
        emailAddress: emailAddress || "",
        phoneNumber: phoneNumber || "",
      };
    }

    if (agentEmail) {
      groundQuoteRequest.delegatedTo = agentEmail;
    }
    return {
      groundQuoteRequest,
      quoteDescription: extraInfo?.description,
      PaymentPriceQuoteRequest:
        PaymentPriceQuoteRequestEnum.CarPriceQuoteRequest,
    };
  }
);

export const hasNoUserPassengersSelector = createSelector(
  getUserPassengers,
  getUserPassengerCallState,
  (userPassengers, userPassengerCallState) =>
    userPassengers.length === 0 && userPassengerCallState === CallState.Success
);

// PAYMENT

export const getRewardsPaymentAccountReferenceId = (state: IStoreState) =>
  state.carBook.rewardsAccountReferenceId;

export const getRewardsPaymentAccount = createSelector(
  getRewardsPaymentAccountReferenceId,
  getRewardsAccounts,
  (accountReferenceId, accounts) =>
    accounts.find((a) => a.accountReferenceId === accountReferenceId)
);

export const getRewardsPaymentInFiatCurrency = (state: IStoreState) =>
  state.carBook.rewardsPaymentInFiatCurrency;

export const getRewardsPaymentInRewardsCurrency = (state: IStoreState) =>
  state.carBook.rewardsPaymentTotal;

export const getRewardsRemainingAfterPurchase = createSelector(
  getRewardsPaymentAccount,
  getRewardsPaymentInFiatCurrency,
  (paymentAccount, rewardsPaymentAmount) => {
    if (!paymentAccount) return "";

    const {
      rewardsCashEquivalent: { value, currencyCode, currencySymbol },
    } = paymentAccount;

    const remainder = value - (rewardsPaymentAmount?.value || 0);

    return getTotalPriceText({
      price: {
        currencyCode,
        currencySymbol,
        value: remainder,
      },
      priceFormatter: twoDecimalFormatter,
    });
  }
);

export const getPricingEstimateTotal = createSelector(
  getCarShopSelectedVehicle,
  (vehicle) => vehicle?.payNow || null
);

export const getTravelWalletOfferToApplyAmount = createSelector(
  getPriceQuote,
  getCarShopSelectedVehicle,
  getTravelWalletItemsToApply,
  (priceQuote, product, travelWalletItemsToApply) => {
    const totalFiatValue =
      priceQuote?.vehAvail.rentalRate.payNow.fiat.value ||
      product?.payNow.fiat.value ||
      0;
    if (travelWalletItemsToApply.offerToApply) {
      if (
        totalFiatValue <
        travelWalletItemsToApply.offerToApply.amount.amount * -1
      ) {
        return totalFiatValue;
      }
      return travelWalletItemsToApply.offerToApply.amount.amount * -1;
    } else {
      return 0;
    }
  }
);

export const getTravelWalletCreditToApplyAmount = createSelector(
  getTravelWalletItemsToApply,
  getPriceQuote,
  getCarShopSelectedVehicle,
  getTravelWalletOfferToApplyAmount,
  (travelWalletItemsToApply, priceQuote, product, offerAppliedAmount) => {
    const totalFiatValue: number =
      priceQuote?.vehAvail.rentalRate.payNow.fiat.value ||
      product?.payNow.fiat.value ||
      0;
    const totalFiatValueWithOfferApplied: number =
      totalFiatValue - offerAppliedAmount;
    if (
      travelWalletItemsToApply.creditToApply &&
      totalFiatValueWithOfferApplied > 0
    ) {
      const maxApplicableCredit =
        travelWalletItemsToApply.offerToApply?.maxApplicableCredit?.amount;
      let amount =
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount
          ? maxApplicableCredit
          : travelWalletItemsToApply.creditToApply.amount.amount;
      if (totalFiatValueWithOfferApplied < amount * -1) {
        if (
          maxApplicableCredit &&
          maxApplicableCredit <
            travelWalletItemsToApply.creditToApply.amount.amount &&
          travelWalletItemsToApply.creditToApply.amount.amount * -1 <
            totalFiatValueWithOfferApplied
        ) {
          return travelWalletItemsToApply.creditToApply.amount.amount * -1;
        }
        return totalFiatValueWithOfferApplied;
      }
      if (
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount
      ) {
        return travelWalletItemsToApply.creditToApply.amount.amount * -1;
      }
      return amount * -1;
    } else {
      return 0;
    }
  }
);

export const getTotalToPay = createSelector(
  getPriceQuote,
  getPricingEstimateTotal,
  getTravelWalletItemsToApply,
  (priceQuote, estimate, travelWalletItemsToApply): Prices | null => {
    let total = priceQuote ? priceQuote?.vehAvail.rentalRate.payNow : estimate;
    let rewardsTotal = {};
    if (
      total &&
      (travelWalletItemsToApply.offerToApply ||
        travelWalletItemsToApply.creditToApply)
    ) {
      let walletItemsTotal =
        travelWalletItemsToApply.offerToApply?.amount.amount || 0;
      if (travelWalletItemsToApply.creditToApply) {
        const maxApplicableCredit =
          travelWalletItemsToApply.offerToApply?.maxApplicableCredit?.amount;
        if (
          maxApplicableCredit &&
          maxApplicableCredit >
            travelWalletItemsToApply.creditToApply.amount.amount
        ) {
          walletItemsTotal += maxApplicableCredit;
        } else {
          walletItemsTotal +=
            travelWalletItemsToApply.creditToApply.amount.amount;
        }
      }
      Object.keys(total.rewards).forEach((accountId) => {
        if (total) {
          let rewardTotalValue = total.rewards[accountId].value;
          rewardTotalValue =
            total.rewards[accountId].currency === "Cash"
              ? rewardTotalValue + walletItemsTotal
              : rewardTotalValue + walletItemsTotal * 100;
          rewardsTotal[accountId] = {
            currency: total.rewards[accountId].currency,
            currencyDescription: total.rewards[accountId].currencyDescription,
            value: rewardTotalValue > 0 ? rewardTotalValue : 0,
          };
        }
      });
      return {
        fiat: {
          currencyCode: total.fiat.currencyCode,
          currencySymbol: total.fiat.currencySymbol,
          value:
            total.fiat.value + walletItemsTotal > 0
              ? total.fiat.value + walletItemsTotal
              : 0,
        },
        rewards: rewardsTotal,
      };
    }
    return priceQuote?.vehAvail.rentalRate.payNow || estimate;
  }
);

export const getTotalToPayLater = createSelector(
  getPriceQuote,
  getCarShopSelectedVehicle,
  (priceQuote, vehicle) =>
    priceQuote?.vehAvail.rentalRate.payAtDesk || vehicle?.payAtDesk
);

export const getIsTravelWalletCreditPaymentOnly = createSelector(
  getTotalToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      !travelWalletItemsToApply.offerToApply &&
      travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getIsTravelWalletOfferPaymentOnly = createSelector(
  getTotalToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      travelWalletItemsToApply.offerToApply &&
      !travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getIsStackedTravelWalletPaymentOnly = createSelector(
  getTotalToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      travelWalletItemsToApply.offerToApply &&
      travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getIsTravelWalletPaymentOnly = createSelector(
  getIsTravelWalletCreditPaymentOnly,
  getIsTravelWalletOfferPaymentOnly,
  getIsStackedTravelWalletPaymentOnly,
  (
    isTravelWalletCreditPaymentOnly,
    isTravelWalletOfferPaymentOnly,
    isStackedTravelWalletPaymentOnly
  ) => {
    if (
      isTravelWalletCreditPaymentOnly ||
      isTravelWalletOfferPaymentOnly ||
      isStackedTravelWalletPaymentOnly
    ) {
      return true;
    }
    return false;
  }
);

export const getMaxApplicableTravelWalletCreditOnlyAmount = createSelector(
  getCredit,
  getPriceQuote,
  getCarShopSelectedVehicle,
  (credit, priceQuote, product) => {
    if (credit) {
      let offerAmount = credit.amount.amount;
      if (priceQuote) {
        let totalFiatValue =
          priceQuote.vehAvail.rentalRate.payNow.fiat.value +
          credit.amount.amount;
        if (totalFiatValue < 0) {
          offerAmount = priceQuote.vehAvail.rentalRate.payNow.fiat.value * -1;
        }
      } else if (product) {
        let totalFiatValue = product.payNow.fiat.value + credit.amount.amount;
        if (totalFiatValue < 0) {
          offerAmount = product.payNow.fiat.value * -1;
        }
      }

      return offerAmount;
    }
    return 0;
  }
);

export const getMaxApplicableTravelWalletCreditAmount = createSelector(
  getCredit,
  getPriceQuote,
  getCarShopSelectedVehicle,
  getTravelWalletOfferToApplyAmount,
  (credit, priceQuote, product, offerAppliedAmount) => {
    if (credit) {
      let creditAmount = credit.amount.amount;
      if (priceQuote) {
        let totalFiatValue =
          priceQuote.vehAvail.rentalRate.payNow.fiat.value +
          credit.amount.amount -
          offerAppliedAmount;
        if (
          priceQuote.vehAvail.rentalRate.payNow.fiat.value -
            offerAppliedAmount <
          credit.amount.amount * -1
        )
          if (totalFiatValue < 0) {
            creditAmount =
              (priceQuote.vehAvail.rentalRate.payNow.fiat.value -
                offerAppliedAmount) *
              -1;
          }
      } else if (product) {
        let totalFiatValue =
          product.payNow.fiat.value + credit.amount.amount - offerAppliedAmount;
        if (totalFiatValue < 0) {
          creditAmount = (product.payNow.fiat.value - offerAppliedAmount) * -1;
        }
      }

      return creditAmount;
    }
    return 0;
  }
);

export const getIsCreditCardPaymentRequired = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  getIsTravelWalletOfferPaymentOnly,
  getIsTravelWalletCreditPaymentOnly,

  getIsStackedTravelWalletPaymentOnly,
  (
    total,
    rewardsPaymentAmount,
    isTravelWalletOfferPaymentOnly,
    isTravelWalletCreditPaymentOnly,
    isStackedTravelWalletPaymetOnly
  ) => {
    if (
      isTravelWalletOfferPaymentOnly ||
      isTravelWalletCreditPaymentOnly ||
      isStackedTravelWalletPaymetOnly
    )
      return false;

    if (!total) return true;
    if (!rewardsPaymentAmount) return true;

    return total.fiat.value > rewardsPaymentAmount.value;
  }
);

export const getTotalCreditCardPaymentRequired = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  (estimate, rewardsPaymentAmount) => {
    if (!estimate) return "";

    if (!rewardsPaymentAmount) {
      return getTotalPriceText({
        price: estimate.fiat,
        priceFormatter: twoDecimalFormatter,
      });
    }

    const remainder = estimate.fiat.value - rewardsPaymentAmount.value;
    const total =
      remainder <= 0 ? 0 : Math.round((remainder + Number.EPSILON) * 100) / 100;

    return getTotalPriceText({
      price: {
        currencyCode: estimate.fiat.currencyCode,
        currencySymbol: estimate.fiat.currencySymbol,
        value: total,
      },
      priceFormatter: twoDecimalFormatter,
    });
  }
);

export const getTotalCreditCardPaymentRequiredNumber = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  (estimate, rewardsPaymentAmount) => {
    if (!estimate) return null;

    if (!rewardsPaymentAmount) {
      return estimate.fiat.value;
    }

    const remainder = estimate.fiat.value - rewardsPaymentAmount.value;
    const total =
      remainder <= 0 ? 0 : Math.round((remainder + Number.EPSILON) * 100) / 100;
    return total;
  }
);

export const getTotalCreditCardPaymentRequiredInFiatPrice = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  (estimate, rewardsPaymentAmount): FiatPrice | undefined => {
    if (!estimate) return undefined;

    if (!rewardsPaymentAmount) return estimate.fiat;

    const remainder = estimate.fiat.value - rewardsPaymentAmount.value;
    const total = remainder <= 0 ? 0 : remainder;

    return {
      currencyCode: estimate.fiat.currencyCode,
      currencySymbol: estimate.fiat.currencySymbol,
      value: total,
    };
  }
);

export const getNumDays = createSelector(
  getDropOffDateSearched,
  getPickUpDateSearched,
  (dropOff, pickUp) => {
    if (!dropOff || !pickUp) {
      return 0;
    }
    return dayjs(dropOff).diff(dayjs(pickUp), "days");
  }
);
export const getTotalChargeFiat = createSelector(
  getCarShopSelectedVehicle,
  getPriceQuote,
  (product, priceQuote) => {
    const totalFiat = priceQuote
      ? priceQuote.vehAvail.rentalRate.totalCharge.fiat
      : product?.totalCharge.fiat;

    if (!totalFiat) return undefined;

    return totalFiat;
  }
);

export const getPaymentMethodRewarsAccountId = (state: IStoreState) =>
  state.carBook.paymentMethodRewardsAccountId;

export const getTotalChargeRewards = createSelector(
  getCarShopSelectedVehicle,
  getRewardsPaymentAccount,
  getPaymentMethodRewarsAccountId,
  getPriceQuote,
  getAllowRewardsWithPolicy,
  (
    product,
    rewardsPaymentAccount,
    cardPaymentRewardsAccountId,
    priceQuote,
    canRedeemRewards
  ) => {
    const rewardsAccount = rewardsPaymentAccount
      ? rewardsPaymentAccount.accountReferenceId
      : cardPaymentRewardsAccountId
      ? cardPaymentRewardsAccountId
      : priceQuote
      ? Object.keys(priceQuote.vehAvail.rentalRate.totalCharge.rewards)[0]
      : product?.totalCharge.rewards &&
        Object.keys(product?.totalCharge.rewards)[0];

    const totalRewards = priceQuote
      ? priceQuote.vehAvail.rentalRate.payNow.fiat.value > 0
        ? priceQuote.vehAvail.rentalRate.payNow.rewards
        : priceQuote.vehAvail.rentalRate.totalCharge.rewards
      : product?.payNow.fiat.value && product?.payNow.fiat.value > 0
      ? product?.payNow.rewards
      : product?.totalCharge.rewards;

    if (!totalRewards || !rewardsAccount || !canRedeemRewards) return undefined;

    return totalRewards[rewardsAccount];
  }
);

export const getCarPricingLineItems = createSelector(
  getCarShopSelectedVehicle,
  getPriceQuote,
  getTotalChargeFiat,
  getTotalChargeRewards,
  (product, priceQuote, totalChargeFiat, totalChargeRewards) => {
    if (priceQuote) {
      const pricing = priceQuote.vehAvail.rentalRate;
      let pricingLineItems: any = [
        [
          {
            title:
              pricing.feesSummary.total.value > 0
                ? textConstants.BASE_FARE
                : textConstants.TOTAL_INC_TAXES_FEES,
            value: getTotalPriceText({
              price: pricing.base,
              priceFormatter: twoDecimalFormatter,
            }),
          },
          ...(pricing.feesSummary.total.value > 0
            ? [
                {
                  title: textConstants.TAXES_AND_FEES,
                  value: getTotalPriceText({
                    price: pricing.feesSummary.total,
                    priceFormatter: twoDecimalFormatter,
                  }),
                },
              ]
            : []),
          ...(pricing.extraInfoSummary?.total &&
          pricing.extraInfoSummary?.total.value > 0
            ? [
                {
                  title: textConstants.ADDITIONAL_FEES,
                  value: pricing.extraInfoSummary
                    ? getTotalPriceText({
                        price: pricing.extraInfoSummary?.total,
                        priceFormatter: twoDecimalFormatter,
                      })
                    : `$0.00`,
                },
              ]
            : []),
        ],
      ];
      if (totalChargeFiat && totalChargeRewards) {
        if (pricing.payAtDesk.value > 0) {
          // Show "Due Today" & "Due at Pickup" lines only if there is payAtDesk value. If not, show only "Total" line
          pricingLineItems.push([
            {
              title: textConstants.TOTAL,
              value: getTotalPriceText({
                price: totalChargeFiat,
                priceFormatter: twoDecimalFormatter,
              }),

              boldLabel: true,
              className: "total-line",
            },
            {
              title: textConstants.DUE_TODAY,
              value: getTotalPriceText({
                price: pricing.payNow.fiat,
                priceFormatter: twoDecimalFormatter,
              }),
              rewardsValue: getRewardsString(totalChargeRewards),
            },
            {
              title: textConstants.DUE_AT_PICKUP,
              value: getTotalPriceText({
                price: pricing.payAtDesk,
                priceFormatter: twoDecimalFormatter,
              }),
            },
          ]);
        } else {
          pricingLineItems.push([
            {
              title: textConstants.TOTAL,
              value: getTotalPriceText({
                price: totalChargeFiat,
                priceFormatter: twoDecimalFormatter,
              }),
              rewardsValue: getRewardsString(totalChargeRewards),
              boldLabel: true,
              className: "total-line",
            },
          ]);
        }
      } else {
        pricingLineItems.push([
          {
            title: textConstants.DUE_TODAY,
            value: getTotalPriceText({
              price: pricing.payNow.fiat,
              priceFormatter: twoDecimalFormatter,
            }),
          },
          {
            title: textConstants.DUE_AT_PICKUP,
            value: getTotalPriceText({
              price: pricing.payAtDesk,
              priceFormatter: twoDecimalFormatter,
            }),
          },
        ]);
      }
      return pricingLineItems;
    }
    if (product) {
      let pricingLineItem: any = [
        [
          {
            title:
              product.fees.value > 0
                ? textConstants.BASE_FARE
                : textConstants.TOTAL_INC_TAXES_FEES,
            value: getTotalPriceText({
              price: product.baseRate,
              priceFormatter: twoDecimalFormatter,
            }),
          },
          ...(product.fees.value > 0
            ? [
                {
                  title: textConstants.TAXES_AND_FEES,
                  value: getTotalPriceText({
                    price: product.fees,
                    priceFormatter: twoDecimalFormatter,
                  }),
                },
              ]
            : []),
        ],
      ];
      if (totalChargeFiat && totalChargeRewards) {
        if (product.payAtDesk.value > 0) {
          // Show "Due Today" & "Due at Pickup" lines only if there is payAtDesk value. If not, show only "Total" line
          pricingLineItem.push([
            {
              title: textConstants.TOTAL,
              value: getTotalPriceText({
                price: totalChargeFiat,
                priceFormatter: twoDecimalFormatter,
              }),

              boldLabel: true,
              className: "total-line",
            },
            {
              title: textConstants.DUE_TODAY,
              value: getTotalPriceText({
                price: product.payNow.fiat,
                priceFormatter: twoDecimalFormatter,
              }),
              rewardsValue: getRewardsString(totalChargeRewards),
            },
            {
              title: textConstants.DUE_AT_PICKUP,
              value: getTotalPriceText({
                price: product.payAtDesk,
                priceFormatter: twoDecimalFormatter,
              }),
            },
          ]);
        } else {
          pricingLineItem.push([
            {
              title: textConstants.TOTAL,
              value: getTotalPriceText({
                price: totalChargeFiat,
                priceFormatter: twoDecimalFormatter,
              }),
              rewardsValue: getRewardsString(totalChargeRewards),
              boldLabel: true,
              className: "total-line",
            },
          ]);
        }
      } else {
        pricingLineItem.push([
          {
            title: textConstants.DUE_TODAY,
            value: getTotalPriceText({
              price: product.payNow.fiat,
              priceFormatter: twoDecimalFormatter,
            }),
          },
        ]);
      }
      return pricingLineItem;
    }
    return [];
  }
);

export const getCarTripTotalInPrices = createSelector(
  getCarShopSelectedVehicle,
  getPriceQuote,
  getRewardsPaymentAccount,
  getRewardsAccountWithLargestEarnForBanner,
  (
    chosenProduct,
    priceQuote,
    rewardsPaymentAccount,
    rewardsAccountWithLargestValue
  ): { fiat: FiatPrice; rewards?: RewardsPrice } | null => {
    const activeRewardsAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;

    const includeRewards = activeRewardsAccount?.allowRewardsRedemption ?? true;

    if (priceQuote && activeRewardsAccount) {
      return {
        fiat: priceQuote.vehAvail.rentalRate.payNow.fiat,
        rewards: includeRewards
          ? priceQuote.vehAvail.rentalRate.payNow.rewards[
              activeRewardsAccount.accountReferenceId
            ]
          : undefined,
      };
    }
    if (chosenProduct && activeRewardsAccount) {
      return {
        fiat: chosenProduct.payNow.fiat,
        rewards: includeRewards
          ? chosenProduct.payNow.rewards[
              activeRewardsAccount.accountReferenceId
            ]
          : undefined,
      };
    }
    return null;
  }
);

export const getCarRewardsAndTotalLineItems = createSelector(
  getRewardsPaymentAccount,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getTotalCreditCardPaymentRequired,
  getSelectedPaymentMethodId,
  getPaymentMethods,
  getTravelWalletItemsToApply,
  getTotalToPayLater,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletCreditToApplyAmount,
  getAllowRewardsWithPolicy,
  (
    rewardsPaymentAccount,
    rewardsTotal,
    rewardsInFiat,
    totalAmountDueOnCredit,
    paymentMethodId,
    paymentMethods,
    travelWalletItemsToApply,
    totalToPayLater,
    offerAmountToApply,
    creditAmountToApply,
    canRedeemRewards
  ) => {
    const rewardsLineItems = [];
    if (
      canRedeemRewards &&
      rewardsPaymentAccount &&
      (rewardsPaymentAccount?.allowRewardsRedemption ?? true)
    ) {
      const fiatPrice = rewardsInFiat ?? {
        currencyCode: rewardsPaymentAccount?.rewardsCashEquivalent.currencyCode,
        currencySymbol:
          rewardsPaymentAccount?.rewardsCashEquivalent.currencySymbol,
        value: 0,
      };

      const priceText = getTotalPriceText({
        price: {
          ...fiatPrice,
          value: fiatPrice.value,
        },
        priceFormatter: twoDecimalFormatter,
      });
      const rewardsText = rewardsTotal ? getRewardsString(rewardsTotal) : "";
      rewardsLineItems.push({
        title: `${rewardsPaymentAccount?.productDisplayName} ${textConstants.REWARDS}`,
        value: `- ${priceText}`,
        rewardsValue: `-${rewardsText}`,
      });
    }

    const paymentMethod = paymentMethods.find((p) => p.id === paymentMethodId);

    let lineItems = [];

    if (totalToPayLater?.value) {
      // Only show "Due Today" if there is a pay later amount
      lineItems.push({
        title: "Due Today",
        value: totalAmountDueOnCredit,
        boldLabel: true,
      });
    }
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      if (travelWalletItemsToApply.offerToApply && offerAmountToApply) {
        lineItems.push({
          title: textConstants.TRAVEL_OFFER_APPLIED,
          value: getTotalPriceText({
            price: {
              value: offerAmountToApply * -1,
              currencyCode:
                travelWalletItemsToApply.offerToApply?.amount.currency,
              currencySymbol: CurrencyFormatters.getSymbol(
                travelWalletItemsToApply.offerToApply?.amount.currency
              ),
            },
            priceFormatter: twoDecimalFormatter,
          }),
          isTravelOffer: true,
          isTravelCredit: false,
          icon: "offer-tag",
          className: "wallet-item",
        });
      }
      if (travelWalletItemsToApply.creditToApply && creditAmountToApply) {
        const breakdownHasStatementCredit =
          !!travelWalletItemsToApply.creditToApply.breakdown?.some(
            (detail) =>
              detail.CreditDetail === "Statement" &&
              Math.abs(detail.usableAmount.amount) > 0
          );
        lineItems.push({
          title: breakdownHasStatementCredit
            ? textConstants.TOTAL_TRAVEL_CREDITS_APPLIED
            : textConstants.TRAVEL_CREDITS_APPLIED,
          value: getTotalPriceText({
            price: {
              value: creditAmountToApply * -1,
              currencyCode:
                travelWalletItemsToApply.creditToApply?.amount.currency,
              currencySymbol: CurrencyFormatters.getSymbol(
                travelWalletItemsToApply.creditToApply?.amount.currency
              ),
            },
            priceFormatter: twoDecimalFormatter,
          }),
          isTravelOffer: false,
          isTravelCredit: true,
          icon: "piggy-bank-icon",
          className: "wallet-item",
          travelCreditBreakdown: breakdownHasStatementCredit
            ? getCheckoutCreditBreakdown(
                travelWalletItemsToApply.creditToApply.breakdown || [],
                creditAmountToApply,
                travelWalletItemsToApply.creditToApply.amount.currency
              )
            : [],
        });
      }
    }
    if (
      rewardsLineItems.length || // Only want to show "Amount Due" line when user has rewards/payment/offers/credits selected
      paymentMethod ||
      !!travelWalletItemsToApply.offerToApply ||
      !!travelWalletItemsToApply.creditToApply
    ) {
      // Only want to show "Amount Due" line when user has rewards/payment selected
      return [
        ...lineItems,
        ...rewardsLineItems,
        {
          title: paymentMethod
            ? `Ending in ${paymentMethod.last4}:`
            : textConstants.AMOUNT_DUE,
          value: totalAmountDueOnCredit,
          icon: paymentMethod ? IconName.Payment : undefined,
        },
      ];
    } else {
      return [...lineItems, ...rewardsLineItems];
    }
  }
);

export enum Progress {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export const getSelectedDriver = createSelector(
  getUserPassengers,
  getUserSelectedTravelerId,
  (drivers, id) => drivers.find((d) => d.id === id)
);
export const getSelectedDriverAge = createSelector(
  getSelectedDriver,
  (driver) =>
    driver
      ? dayjs().diff(
          getDateTimeWithFormat(driver.dateOfBirth, "YYYY-MM-DD"),
          "year"
        )
      : DEFAULT_DRIVER_AGE
);

export const getCombinedBookingSteps = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedTravelerId,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccountReferenceId,
  getIsCreditCardPaymentRequired,
  getAllowRewardsWithPolicy,
  (
    email,
    phoneNumber,
    traveler,
    selectedPayment,
    selectedRewardsAccount,
    creditCardRequired,
    allowRewards
  ) => {
    const paymentCompleted = () => {
      const unsettledPayment = !selectedPayment && creditCardRequired;

      if (selectedRewardsAccount && unsettledPayment) {
        return Progress.IN_PROGRESS;
      }
      if (!selectedRewardsAccount && !selectedPayment) {
        return Progress.NOT_STARTED;
      }
      return Progress.COMPLETED;
    };
    return [
      {
        name: textConstants.DRIVER_INFORMATION,
        status:
          !!traveler &&
          email &&
          phoneNumber &&
          emailRegex.test(email) &&
          phoneRegex.test(phoneNumber)
            ? Progress.COMPLETED
            : Progress.IN_PROGRESS,
      },
      {
        name: textConstants.REWARDS_AND_PAYMENT(allowRewards),
        status: paymentCompleted(),
      },
    ];
  }
);

export const getBookingProgress = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedTravelerId,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccountReferenceId,
  getIsCreditCardPaymentRequired,
  getAllowRewardsWithPolicy,
  (
    email,
    phoneNumber,
    traveler,
    selectedPayment,
    selectedRewardsAccount,
    creditCardRequired,
    allowRewards
  ) => {
    const paymentCompleted = () => {
      const unsettledPayment = !selectedPayment && creditCardRequired;

      if (selectedRewardsAccount && unsettledPayment) {
        return Progress.IN_PROGRESS;
      }
      if (!selectedRewardsAccount && !selectedPayment) {
        return Progress.NOT_STARTED;
      }
      return Progress.COMPLETED;
    };

    return [
      {
        name: textConstants.ADD_TRAVELERS,
        status: traveler ? Progress.COMPLETED : Progress.IN_PROGRESS,
      },
      {
        name: textConstants.CONTACT,
        status:
          email &&
          phoneNumber &&
          emailRegex.test(email) &&
          phoneRegex.test(phoneNumber)
            ? Progress.COMPLETED
            : Progress.NOT_STARTED,
      },
      {
        name: textConstants.REWARDS_AND_PAYMENT(allowRewards),
        status: paymentCompleted(),
      },
    ];
  }
);

export const getBookingInProgress = createSelector(
  getSchedulePriceQuoteCallState,
  getPollPriceQuoteCallState,
  getScheduleBookCallState,
  getConfirmationDetailsCallState,
  (scheduleQuote, pollPriceQuote, scheduleBook, confirmDetails) =>
    scheduleQuote === CallState.InProcess ||
    pollPriceQuote === CallState.InProcess ||
    scheduleBook === CallState.InProcess ||
    confirmDetails === CallState.InProcess
);

export const getIsBookingValid = createSelector(
  getPriceDifference,
  getPriceDifferenceAcknowledged,
  getIsCreditCardPaymentRequired,
  getSelectedPaymentMethodId,
  getUserSelectedTravelerId,
  getBookingInProgress,
  getContactEmail,
  getContactPhone,
  getPollPriceQuoteCallState,
  getIsTravelWalletPaymentOnly,
  (
    priceDifference,
    priceDifferenceAcknowledged,
    creditCardRequired,
    selectedPaymentMethodId,
    getUserSelectedTravelerId,
    bookingInProgress,
    email,
    phone,
    pollPriceQuoteCallState,
    isTravelWalletPaymentOnly
  ) => {
    const paymentUnsettled =
      creditCardRequired &&
      !selectedPaymentMethodId &&
      !isTravelWalletPaymentOnly;

    if (
      (priceDifference.hasDifference && !priceDifferenceAcknowledged) ||
      paymentUnsettled ||
      !getUserSelectedTravelerId ||
      bookingInProgress ||
      !email ||
      !phone ||
      pollPriceQuoteCallState !== CallState.Success
    ) {
      return false;
    }
    return true;
  }
);

export const getEarn = (state: IStoreState) => state.carBook.productEarnValue;

export const getEarnByRewardsAccountId = (state: IStoreState) =>
  state.carBook.earnValuesByRewardAcctId;

const getPaymentRequestType = createSelector(
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  (
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    selectedPaymentMethodId
  ) => {
    if (selectedPaymentMethodId && !rewardsPayment) {
      return PaymentSplitRequestEnum.PaymentCardRequest;
    }
    if (
      rewardsAccountId &&
      rewardsPayment &&
      rewardsFiatPayment &&
      creditCardPayment?.value === 0
    ) {
      return PaymentSplitRequestEnum.PaymentRewardsRequest;
    }
    if (rewardsPayment && rewardsAccountId && rewardsFiatPayment) {
      return PaymentSplitRequestEnum.PaymentCardRewardsRequest;
    }
    return null;
  }
);

export const getCardPaymentRewardsAccount = createSelector(
  getPaymentMethodRewarsAccountId,
  getRewardsAccounts,
  (accountId, rewardsAccounts) =>
    rewardsAccounts.find((account) => account.accountReferenceId === accountId)
);

export const getEarnedString = createSelector(
  getEarn,
  getCardPaymentRewardsAccount,
  getAllowRewardsWithPolicy,
  (earn, account, canRedeemRewards) => {
    if (!earn || !account || !canRedeemRewards) return "";
    return textConstants.EARNED_STRING({
      ...account.rewardsBalance,
      value: earn,
    });
  }
);

export const getPaymentRequest = createSelector(
  getPaymentMethodRewarsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,
  getSession,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    session
  ): PaymentSplitRequest | null => {
    let amount: PaymentType | null = null;
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const cardPaymentType: UserCardPaymentType = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            currency: creditCardPayment!.currencyCode,
            amount: roundToTwoDecimals(creditCardPayment!.value),
            PaymentAmount: PaymentAmountEnum.FiatAmount,
          },
          Payment: TypeOfPaymentEnum.UserCard,
        };
        amount = cardPaymentType;
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitPaymentType: SplitPaymentType = {
          paymentId: paymentId || "",
          accountReferenceId,
          Payment: TypeOfPaymentEnum.Split,
          paymentAmount: {
            fiatAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
              PaymentAmount: PaymentAmountEnum.FiatAmount,
            },
            rewardsAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
              PaymentAmount: PaymentAmountEnum.RewardsAmount,
            },
            PaymentAmount: PaymentAmountEnum.SplitAmount,
          },
        };
        amount = splitPaymentType;
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: RewardsPaymentType = {
          paymentAmount: {
            rewardsAccountId: rewardsAccountId!,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatPayment!.value),
              currency: rewardsFiatPayment!.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsPayment!.value),
              currency: rewardsPayment!.currency,
            },
            PaymentAmount: PaymentAmountEnum.RewardsAmount,
          },
          Payment: TypeOfPaymentEnum.Rewards,
        };
        amount = rewardsPaymentType;
        break;
      default:
        return null;
    }

    if (amount && session) {
      const request = {
        token: session,
        payment: amount,
        ancillaries: [],
      };
      return request;
    }
    return null;
  }
);

export const getPaymentRequestV2 = createSelector(
  getPaymentMethodRewarsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,
  getSession,
  getTravelWalletItemsToApply,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletCreditToApplyAmount,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    session,
    travelWalletItemsToApply,
    offerToApplyAmount,
    creditToApplyAmount
  ): PaymentSplitRequestV2 | null => {
    let amount: PaymentV2[] = [];
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      if (travelWalletItemsToApply.offerToApply) {
        const offer: TravelWalletOfferPayment = {
          offerId: travelWalletItemsToApply.offerToApply.id,
          description:
            travelWalletItemsToApply.offerToApply?.descriptions[0] || "",
          paymentAmount: {
            fiatValue: {
              amount: offerToApplyAmount,
              currency: travelWalletItemsToApply.offerToApply.amount.currency,
            },
          },
          PaymentV2: PaymentV2Enum.TravelWalletOffer,
        };
        amount.push(offer);
      }
      if (travelWalletItemsToApply.creditToApply && creditToApplyAmount > 0) {
        const credit: TravelWalletCreditPayment = {
          offerId: travelWalletItemsToApply.creditToApply.id,
          description: "TravelWalletCredit", // creditToApply doesn't have a description
          paymentAmount: {
            fiatValue: {
              amount: roundToTwoDecimals(creditToApplyAmount),
              currency: travelWalletItemsToApply.creditToApply.amount.currency,
            },
          },
          PaymentV2: PaymentV2Enum.TravelWalletCredit,
        };
        amount.push(credit);
      }
    }
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const userCardPayment: UserCard = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            currency: creditCardPayment!.currencyCode,
            amount: roundToTwoDecimals(creditCardPayment!.value),
          },
          PaymentV2: PaymentV2Enum.UserCard,
        };
        amount.push(userCardPayment);
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitUserCardPayment: UserCard = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            currency: creditCardPayment!.currencyCode,
            amount: roundToTwoDecimals(creditCardPayment!.value),
          },
          PaymentV2: PaymentV2Enum.UserCard,
        };

        const splitRewardsPayment: Rewards = {
          paymentAmount: {
            rewardsAccountId: rewardsAccountId!,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatPayment!.value),
              currency: rewardsFiatPayment!.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsPayment!.value),
              currency: rewardsPayment!.currency,
            },
          },
          PaymentV2: PaymentV2Enum.Rewards,
        };

        amount.push(splitUserCardPayment, splitRewardsPayment);
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: Rewards = {
          paymentAmount: {
            rewardsAccountId: rewardsAccountId!,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatPayment!.value),
              currency: rewardsFiatPayment!.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsPayment!.value),
              currency: rewardsPayment!.currency,
            },
          },
          PaymentV2: PaymentV2Enum.Rewards,
        };
        amount.push(rewardsPaymentType);
        break;
      default:
        break;
    }

    if (amount && session) {
      const request = {
        token: session,
        payments: amount,
        ancillaries: [],
      };
      return request;
    } else {
      return null;
    }
  }
);

export const getOpaquePayments = createSelector(
  getPaymentMethodRewarsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,

  getTravelWalletItemsToApply,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletCreditToApplyAmount,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    travelWalletItemsToApply,
    offerToApplyAmount,
    creditToApplyAmount
  ): PaymentOpaqueValue[] | null => {
    let amount: PaymentOpaqueValue[] = [];
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      if (travelWalletItemsToApply.offerToApply) {
        const offer: PaymentOpaqueValue = {
          type: Payment.TravelWalletOffer,
          value: {
            offerId: travelWalletItemsToApply.offerToApply.id,
            description:
              travelWalletItemsToApply.offerToApply?.descriptions[0] || "",
            paymentAmount: {
              fiatValue: {
                amount: offerToApplyAmount,
                currency: travelWalletItemsToApply.offerToApply.amount.currency,
              },
            },
            PaymentV2: PaymentV2Enum.TravelWalletOffer,
          },
        };
        amount.push(offer);
      }
      if (travelWalletItemsToApply.creditToApply && creditToApplyAmount > 0) {
        const credit: PaymentOpaqueValue = {
          type: Payment.TravelWalletCredit,
          value: {
            offerId: travelWalletItemsToApply.creditToApply.id,
            description: "TravelWalletCredit", // creditToApply doesn't have a description
            paymentAmount: {
              fiatValue: {
                amount: roundToTwoDecimals(creditToApplyAmount),
                currency:
                  travelWalletItemsToApply.creditToApply.amount.currency,
              },
            },
            PaymentV2: PaymentV2Enum.TravelWalletCredit,
          },
        };
        amount.push(credit);
      }
    }
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const userCardPayment: PaymentOpaqueValue = {
          type: Payment.Card,
          value: {
            paymentId: paymentId || "",
            accountReferenceId,
            paymentAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
            },
            PaymentV2: PaymentV2Enum.UserCard,
          },
        };
        amount.push(userCardPayment);
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitUserCardPayment: PaymentOpaqueValue = {
          type: Payment.Card,
          value: {
            paymentId: paymentId || "",
            accountReferenceId,
            paymentAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
            },
            PaymentV2: PaymentV2Enum.UserCard,
          },
        };

        const splitRewardsPayment: PaymentOpaqueValue = {
          type: Payment.Rewards,
          value: {
            paymentAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
            },
            PaymentV2: PaymentV2Enum.Rewards,
          },
        };

        amount.push(splitUserCardPayment, splitRewardsPayment);
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: PaymentOpaqueValue = {
          type: Payment.Rewards,
          value: {
            paymentAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
            },
            PaymentV2: PaymentV2Enum.Rewards,
          },
        };
        amount.push(rewardsPaymentType);
        break;
      default:
        break;
    }

    if (amount) {
      return amount;
    } else {
      return null;
    }
  }
);

export const getCardPaymentAccount = createSelector(
  getRewardsAccounts,
  getPaymentRequest,
  (rewardsAccounts, paymentRequest: PaymentSplitRequest | null) => {
    switch (paymentRequest?.payment.Payment) {
      case TypeOfPaymentEnum.UserCard:
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId ===
            (paymentRequest?.payment as UserCardPaymentType).accountReferenceId
        );
      case TypeOfPaymentEnum.Split:
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId ===
            (paymentRequest?.payment as SplitPaymentType).accountReferenceId
        );
      default:
        return null;
    }
  }
);

export enum CheckoutSteps {
  ADD_TRAVELERS = 0,
  CONTACT = 1,
  REWARDS_AND_PAYMENT = 2,
}

export const areAllStepsCompletedInCheckout = createSelector(
  getBookingProgress,
  getIsTravelWalletPaymentOnly,
  (checkoutProgress, isTravelWalletPaymentOnly) =>
    checkoutProgress[CheckoutSteps.ADD_TRAVELERS].status ===
      Progress.COMPLETED &&
    checkoutProgress[CheckoutSteps.CONTACT].status === Progress.COMPLETED &&
    (!isTravelWalletPaymentOnly
      ? checkoutProgress[CheckoutSteps.REWARDS_AND_PAYMENT].status ===
        Progress.COMPLETED
      : true)
);

export const verifyPaymentMethodResultSelector = (state: IStoreState) =>
  state.carBook.verifyPaymentMethodResult;

export const getErrorTitles = createSelector(
  getHasError,
  getPriceQuoteErrors,
  getScheduleBookError,
  getSchedulePriceQuoteError,
  getConfirmationDetailsError,
  getPriceDifference,
  verifyPaymentMethodResultSelector,
  getCarShopSelectedVehicle,
  getTravelWalletItemsToApply,
  getHertzLoyaltyRewardsNumber,
  getUseV1PurchaseFlow,
  (
    hasError,
    priceQuoteErrors,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsErrors,
    priceDifference,
    verifyPaymentMethodResult,
    selectedVehicle,
    travelWalletItemsToApply,
    hertzLoyaltyRewardsNumber,
    useV1PurchaseFlow
  ): ErrorTitles => {
    if (!hasError) return { title: "", subtitle: "", primaryButtonText: "" };

    if (priceDifference.hasDifference && priceDifference.amount) {
      const primaryButtonText = textConstants.CONTINUE;

      const secondaryButtonText = textConstants.CHOOSE_ANOTHER_CAR;

      const offerAmount =
        travelWalletItemsToApply.offerToApply?.amount.amount || 0;
      const creditsAmount =
        travelWalletItemsToApply.creditToApply?.amount.amount || 0;

      const walletItemCoverageChanged =
        (travelWalletItemsToApply.offerToApply ||
          travelWalletItemsToApply.creditToApply) &&
        Math.abs(offerAmount + creditsAmount) >=
          priceDifference.predictedTotal &&
        Math.abs(offerAmount + creditsAmount) < priceDifference.priceQuoteTotal;

      if (walletItemCoverageChanged) {
        return {
          title: travelWalletItemsToApply.creditToApply
            ? textConstants.PRICE_INCREASE_CREDITS_COVERAGE_TITLE
            : textConstants.PRICE_INCREASE_OFFER_COVERAGE_TITLE,
          subtitle: textConstants.PRICE_INCREASE_WALLET_COVERAGE_SUBTITLE,
          primaryButtonText,
          secondaryButtonText,
          icon: priceDifference.isIncrease
            ? textConstants.PRICE_INCREASE_ICON
            : textConstants.PRICE_DECREASE_ICON,
        };
      }

      return {
        title: textConstants.getPriceDifferenceTitle(
          priceDifference.isIncrease,
          priceDifference.amount
        ),
        subtitle: priceDifference.isIncrease
          ? hertzLoyaltyRewardsNumber
            ? textConstants.HERTZ_LOYALTY_INCREASE_SUBTITLE
            : textConstants.PRICE_INCREASE_SUBTITLE
          : textConstants.PRICE_DECREASE_SUBTITLE,
        primaryButtonText,
        secondaryButtonText,
        icon: priceDifference.isIncrease
          ? textConstants.PRICE_INCREASE_ICON
          : textConstants.PRICE_DECREASE_ICON,
      };
    }

    if (
      verifyPaymentMethodResult &&
      verifyPaymentMethodResult !== PaymentVerifyResultEnum.Success
    ) {
      const defaultTitle = verifyPaymentMethodResult.toString();

      return {
        ...textConstants.PAYMENT_METHOD_ERROR_TITLES(defaultTitle),
        agentSubtitle: getAgentErrorSubtitle(verifyPaymentMethodResult),
        agentTitle: getAgentErrorTitle(verifyPaymentMethodResult),
      };
    }

    if (confirmationDetailsErrors.length > 0) {
      const returnProps = useV1PurchaseFlow
        ? getV1ErrorMessage(
            confirmationDetailsErrors[0] as PurchaseError,
            selectedVehicle?.supplierRef
          )
        : getErrorMessage(
            confirmationDetailsErrors[0] as PaymentError,
            selectedVehicle?.supplierRef
          );
      return returnProps;
    }

    if (scheduleBookError) {
      return textConstants.GENERIC_ERROR_TITLES;
    }

    if (schedulePriceQuoteError) {
      return textConstants.GENERIC_ERROR_TITLES;
    }

    if (priceQuoteErrors.length > 0) {
      return useV1PurchaseFlow
        ? getV1ErrorMessage(
            priceQuoteErrors[0] as PurchaseError,
            selectedVehicle?.supplierRef
          )
        : getErrorMessage(
            priceQuoteErrors[0] as PaymentError,
            selectedVehicle?.supplierRef
          );
    }

    const returnProps = textConstants.GENERIC_ERROR_TITLES;

    return returnProps;
  }
);

export const getReviewedCarDetailsCheckoutProperties = createSelector(
  getViewedCarDetailsProperties,
  getDriverAge,
  getTotalToPay,
  getTotalToPayLater,
  getExtraInfo,
  getPaymentMethods,
  (
    viewedCarDetailsProperties,
    driverAge,
    payNow,
    payLater,
    extraInfo,
    paymentMethods
  ): ITrackingProperties<ReviewedCarDetailsCheckoutProperties> => {
    let props = {
      properties: {
        ...viewedCarDetailsProperties.properties,
        driver_age: driverAge || DEFAULT_DRIVER_AGE,
        pay_now: payNow?.fiat.value || 0,
        pay_later: payLater?.value || 0,
        estimated_commission: 0,
        card_on_file: paymentMethods.length > 0,
      },
      encryptedProperties: [...viewedCarDetailsProperties.encryptedProperties],
    };
    if (extraInfo) {
      extraInfo.elements.forEach((element) => {
        props = {
          properties: {
            ...props.properties,
            ...element.trackingPropertiesV2?.properties,
          },
          encryptedProperties: [
            ...props.encryptedProperties,
            element.trackingPropertiesV2?.encryptedProperties ?? "",
          ],
        };
      });
    }
    return props;
  }
);

export const getSelectedPaymentCardType = createSelector(
  getRewardsAccounts,
  getPaymentMethods,
  getSelectedPaymentMethodId,
  (accounts, paymentMethods, id): string => {
    const paymentMethod = paymentMethods.find((p) => p.id === id);
    if (
      accounts[0]?.customerAccountRole === CustomerAccountRole.NonFinancialUser
    ) {
      return paymentMethod?.cardType ?? "";
    }
    return (
      accounts.find(
        (a) =>
          a.lastFour === paymentMethod?.last4 ||
          a.lastFourVirtualCardNumbers?.includes(paymentMethod?.last4 || "")
      )?.productDisplayName || ""
    );
  }
);

export const getPriceQuoteCarProperties = createSelector(
  getReviewedCarDetailsCheckoutProperties,
  getSession,
  getTravelWalletItemsToApply,
  (
    reviewedCarDetailsCheckoutProperties,
    session,
    travelWalletItemsToApply
  ): ITrackingProperties<PriceQuoteCarProperties> => ({
    properties: {
      ...reviewedCarDetailsCheckoutProperties.properties,
      success: false,
      booking_session_id: session?.value || "",
      ...travelWalletItemsToApply.offerToApply?.trackingPropertiesV2
        ?.properties,
      ...travelWalletItemsToApply.creditToApply?.trackingPropertiesV2
        ?.properties,
    },
    encryptedProperties: [
      ...reviewedCarDetailsCheckoutProperties.encryptedProperties,
      travelWalletItemsToApply.offerToApply?.trackingPropertiesV2
        ?.encryptedProperties ?? "",
      travelWalletItemsToApply.creditToApply?.trackingPropertiesV2
        ?.encryptedProperties ?? "",
    ],
  })
);

export const getIsPaymentMethodVCN = createSelector(
  getPaymentMethodRewarsAccountId,
  getPaymentMethod,
  getRewardsAccounts,
  (paymentRewardsAccountId, paymentMethod, rewardsAccounts) => {
    if (!paymentRewardsAccountId || !paymentMethod || !rewardsAccounts)
      return false;

    const account = rewardsAccounts.find(
      (account) => account.accountReferenceId === paymentRewardsAccountId
    );

    return !!(
      account?.lastFourVirtualCardNumbers &&
      paymentMethod?.last4 &&
      account?.lastFourVirtualCardNumbers?.includes(paymentMethod?.last4)
    );
  }
);

export const getCompleteBuyCarProperties = createSelector(
  getReviewedCarDetailsCheckoutProperties,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentAccount,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getSelectedPaymentCardType,
  getSession,
  getConfirmationDetails,
  getOffers,
  getTravelWalletCreditToApplyAmount,
  getTravelWalletOfferToApplyAmount,
  getOfferToApply,
  getCreditToApply,
  getIsPaymentMethodVCN,
  getCarAvailabilityEntryPoint,
  getSelectedVehiclePolicyCompliance,
  getTripPurpose,
  (
    reviewedCarDetailsCheckoutProperties,
    rewardsPaymentFiat,
    rewardsPayment,
    account,
    creditCardPayment,
    cardType,
    session,
    groundBooking,
    offers,
    creditToApplyAmount,
    offerToApplyAmount,
    offerToApply,
    creditToApply,
    isPaymentMethodVCN,
    carAvailabilityEntryPoint,
    selectedVehiclePolicyCompliance,
    tripPurpose
  ): ITrackingProperties<CompleteBuyCarProperties> => {
    const isInPolicy = selectedVehiclePolicyCompliance?.isInPolicy ?? true;
    return {
      properties: {
        ...reviewedCarDetailsCheckoutProperties.properties,
        success: false,
        total_rewards_amount_usd: rewardsPaymentFiat?.value || 0,
        total_card_amount_usd: creditCardPayment?.value || 0,
        card_product_used: cardType || "",
        rewards_product_used: account?.productDisplayName || "",
        rewards_currency: rewardsPayment?.currency || "",
        agent_booking_fee_amount_usd: 0,
        booking_session_id: session?.value || "",
        ground_booking_id: groundBooking?.groundBookingId || "",
        ...offerToApply?.trackingPropertiesV2?.properties,
        offer_amount_redeemed_usd:
          !!offerToApply && !!offerToApplyAmount
            ? Math.abs(offerToApplyAmount)
            : 0,
        offer_count: offers?.length,
        offer_used: !!offerToApply,
        credit_amt_redeemed:
          !!creditToApply && !!creditToApplyAmount
            ? Math.abs(creditToApplyAmount)
            : 0,
        credit_redeemed: !!creditToApply?.amount?.amount,
        is_vcn: isPaymentMethodVCN,
        entry_type: carAvailabilityEntryPoint,
        pax_count: groundBooking?.vehicle.passengerCapacity,
        bags_count: groundBooking?.vehicle.bagCapacity[BagSize.Unspecified],
        pickup_location: groundBooking?.pickUp.location.address,
        car_type: groundBooking?.vehicle.kind,
        same_dropoff: isEqual(
          groundBooking?.pickUp.location,
          groundBooking?.dropOff.location
        ),
        ...(isCorpTenant(config.TENANT) && {
          in_policy: isInPolicy,
          ...(!isInPolicy && {
            policy_reason: selectedVehiclePolicyCompliance?.reasons.join(", "),
          }),
          purpose_of_travel: tripPurpose,
        }),
      },
      encryptedProperties: [
        ...reviewedCarDetailsCheckoutProperties.encryptedProperties,
        offerToApply?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getModalType = createSelector(
  getPriceQuoteErrors,
  getScheduleBookError,
  getSchedulePriceQuoteError,
  getConfirmationDetailsError,
  getPriceDifference,
  verifyPaymentMethodResultSelector,
  (
    priceQuoteErrors,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsErrors,
    priceDifference,
    verifyPaymentMethodResult
  ): string => {
    if (priceDifference.hasDifference && priceDifference.amount) {
      return "car_price_difference";
    }

    if (
      verifyPaymentMethodResult &&
      verifyPaymentMethodResult !== PaymentVerifyResultEnum.Success
    ) {
      return "payment_verification_failed";
    }

    if (confirmationDetailsErrors.length > 0) {
      return "confirmation_details_failed";
    }

    if (scheduleBookError) {
      return "schedule_book_failed";
    }

    if (schedulePriceQuoteError) {
      return "schedule_price_quote_failed";
    }

    if (priceQuoteErrors.length > 0) {
      return "poll_price_quote_failed";
    }

    return "";
  }
);

export const isContactStepComplete = createSelector(
  getBookingProgress,
  (checkoutProgress) =>
    checkoutProgress[CheckoutSteps.CONTACT].status === Progress.COMPLETED
);

export const isTravelerStepComplete = createSelector(
  getBookingProgress,
  (checkoutProgress) =>
    checkoutProgress[CheckoutSteps.ADD_TRAVELERS].status === Progress.COMPLETED
);

export const isFirstStepCompletedInCheckout = createSelector(
  getBookingProgress,
  (checkoutProgress) =>
    checkoutProgress[CheckoutSteps.ADD_TRAVELERS].status === Progress.COMPLETED
);

export const rewardsAccountMinimumRequirementStateSelector = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccountMinimumRequirementState =>
    getRewardsAccountMinimumRequirementState(rewardsAccounts)
);

export const getIsHertzProvider = createSelector(
  getCarShopSelectedVehicle,
  (vehicle) => vehicle?.vendorRef === "HE"
);

export const getOffersRequest = createSelector(
  getCarShopSelectedVehicle,
  getPickUpLocationSearched,
  getPriceQuote,
  (
    selectedVehicle,
    pickUpLocationSearched,
    priceQuote
  ): OffersForProductsRequest | null => {
    if (selectedVehicle) {
      const pickUpAirportCode: string | undefined = (
        pickUpLocationSearched?.id as any
      )?.code?.code;

      const airport: LocationCode | undefined = pickUpAirportCode
        ? {
            kind: LocationKindEnum.Airport,
            value: pickUpAirportCode,
          }
        : undefined;

      return {
        products: [
          {
            AvailableProduct: AvailableProductEnum.CarRental,
            vehicleId: selectedVehicle?.vehicle.id,
            pickupDateTime: selectedVehicle?.pickUp.time,
            dropOffDateTime: selectedVehicle?.dropOff.time,
            canBeDiscounted: true,
            requiresCreditCard: selectedVehicle.requiresCreditCard,
            vendor: selectedVehicle.vendorRef,
            supplier: selectedVehicle.supplierRef,
            rateCode: selectedVehicle.rateCode,
            airport,
            // taken from https://github.com/hopper-org/cars-common/blob/bcf65fdc51205fcb360f4dcd4f16670057af9d93/cars-tracking/src/main/scala/com/hopper/cars/common/tracking/package.scala#L25-L34
            productPricing: {
              productClass: ProductClassEnum.Economy,
              currency:
                priceQuote?.vehAvail.rentalRate.totalCharge.fiat.currencyCode ??
                selectedVehicle.totalCharge.fiat.currencyCode,
              basePrice:
                priceQuote?.vehAvail.rentalRate.base.value ??
                selectedVehicle.baseRate.value,
              grossBookValue:
                priceQuote?.vehAvail.rentalRate.totalCharge.fiat.value ??
                selectedVehicle.totalCharge.fiat.value,
              ancillaryTotal: 0,
              preDiscountTotal:
                priceQuote?.vehAvail.rentalRate.discount?.newTotalCharge
                  .value ??
                priceQuote?.vehAvail.rentalRate.totalCharge.fiat.value ??
                selectedVehicle.totalCharge.fiat.value,
              preDiscountGrandTotal:
                priceQuote?.vehAvail.rentalRate.discount?.newTotalCharge
                  .value ??
                priceQuote?.vehAvail.rentalRate.totalCharge.fiat.value ??
                selectedVehicle.totalCharge.fiat.value,
              sellPrice:
                priceQuote?.vehAvail.rentalRate.totalCharge.fiat.value ??
                selectedVehicle.totalCharge.fiat.value,
            },
          },
        ],
      };
    } else {
      return null;
    }
  }
);

export const getv1FulfillSession = (state: IStoreState) =>
  state.carBook.v1FullfllSessionToken;

export const getRewardsAccountsWithPaymentAccounts = createSelector(
  getRewardsAccounts,
  getPaymentMethods,
  (rewardsAccounts, paymentMethods) => {
    const accountsWithSavedPayment = paymentMethods.reduce(
      (savedPayments, currentAcc) => {
        const matchingRewardsAcct = rewardsAccounts.find(
          (rewards) =>
            rewards.lastFour === currentAcc.last4 ||
            rewards.lastFourVirtualCardNumbers?.includes(currentAcc.last4 || "")
        );

        if (matchingRewardsAcct) {
          savedPayments.push(matchingRewardsAcct);
        }
        const isTestCard =
          window.__mclean_env__.ENV !== "production" &&
          currentAcc.last4 &&
          TEST_CARD_LAST_FOURS.includes(currentAcc.last4);
        if (isTestCard) {
          savedPayments.push(rewardsAccounts[0]);
        }

        return savedPayments;
      },
      [] as RewardsAccount[]
    );
    return accountsWithSavedPayment;
  }
);

// Corporate Travel
export const getCarAvailabilityRequest = (state: IStoreState) =>
  state.carAvailability.carAvailabilityRequest;

export const getPaymentsWithRewardAccounts = createSelector(
  getRewardsAccounts,
  getPaymentMethods,
  (rewardAccounts, paymentMethods) =>
    paymentMethods.filter((payment) =>
      rewardAccounts.find((account) => account.lastFour === payment.last4)
    )
);

export const getExpiredCards = createSelector(
  getPaymentsWithRewardAccounts,
  getRewardsAccounts,
  (availablePaymentMethods, rewardsAccounts) => {
    const expiredCardAccountsLastFour = availablePaymentMethods.reduce(
      (expiredAccounts: string[], account: CreditCard) => {
        const isExpired =
          account.year < dayjs().year() ||
          (account.year === dayjs().year() &&
            account.month < dayjs().month() + 1);
        if (isExpired && account.last4) {
          return [...expiredAccounts, account.last4];
        }

        return expiredAccounts;
      },
      []
    );

    return rewardsAccounts.filter((rewardsAccount) =>
      expiredCardAccountsLastFour.includes(rewardsAccount.lastFour)
    );
  }
);
